import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { breakpoints, fonts, colors } from "../../style-utilities/variables";
import ShareWidget from "../../components/ShareWidget";
import ServicesContainer from "../../components/ServicesContainer";

const StyledDetailsSidebar = styled.div`
  margin-top: 4em;
  margin-right: 2em;
  margin-left: auto;

  .author {
    a {
      font-size: 22px;
    }
    h4 {
      color: ${colors.primaryGray};
      font-size: 20px;
      margin-top: 0.5em;
    }
    .author-image {
      max-width: 180px;
      margin-bottom: 2em;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .bio {
      display: none;
    }
  }

  .left-subDivs {
    width: 50%;
    padding-right: 20px;
    max-width: 280px;
    float: left;
  }
  .right-subDivs {
    max-width: 280px;
    width: 50%;
    float: right;
  }

  .subDivs {
    padding-bottom: 0.7rem;
    h3 {
      margin-bottom: 0.6rem;
      font-size: 22px;
    }
    ul {
      margin-bottom: 0;
      margin-left: 0;
    }
    p,
    li {
      font-size: 16px;
      list-style: none;
      color: ${colors.primaryGray};
      margin-bottom: 0;
    }
    a {
      color: ${colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 210px;
    .author {
      .author-image {
        margin-bottom: 0;
      }
      .bio {
        display: inline-block;
      }
    }
    .right-subDivs,
    .left-subDivs {
      width: 100%;
    }
    .left-subDivs {
      padding-right: 0;
    }
  }
`;

const BlogSideBar = props => {

  var renderServices = "";

  for (var key in props.services) {
    renderServices += props.services[key] + "<br>";
  }


  return (
    <StyledDetailsSidebar>
      <div className="left-subDivs">
        <div className="subDivs bottom-line">
          <h3>Date</h3>
          <p>{props.date}</p>
        </div>
        <div className="subDivs bottom-line author">
          <h3>Author</h3>
          <div className="author-image">
            <img src={props.photo} />
          </div>
          <Link to={`/blog/author/` + props.authorLink}>
            {props.authorName}
          </Link>
          <h4>{props.title}</h4>
          <p className="bio">{ReactHtmlParser(props.bio)}</p>
        </div>
      </div>

      <div className="right-subDivs">
        <div className="topic subDivs bottom-line">
          <h3>Topics</h3>
          {props.topics.map((topic, index) => (
            <p key={index} className="tags">
              <Link to={`/insights/blog/?=${props.tagId[index]}`}>{ReactHtmlParser(topic)}</Link>
            </p>
          ))}
        </div>
        <div className="services subDivs bottom-line">
          <h3>Solutions</h3>
          {ReactHtmlParser(renderServices)}
        </div>
        <div className="share subDivs">
          <h3>Share</h3>
          <ShareWidget/>
        </div>
      </div>
    </StyledDetailsSidebar>
  );
};

export default BlogSideBar;
