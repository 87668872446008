import React from "react";
import { Link } from "gatsby";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import styled from "styled-components";

// import components
import SEO from "../../components/SEO";
import ShareWidget from "../ShareWidget";
import NavBar from "../../components/NavBar";
import CircleSection from "../../components/CircleSection";
import ContactBanner from "./../ContactBanner";

const StyledWhitePaper = styled.div`
  margin: 0 auto;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  h1 {
    font-size: 48px;
    margin-bottom: 1.45em;
    text-transform: none;
  }
  h3 {
    margin-bottom: 0.5em;
  }
  h3,
  .sub-head {
    font-size: 22px;
  }
  strong {
    color: black;
  }
  .bottom-line {
    border-bottom: 2px solid ${colors.lightGray};
    margin-bottom: 1.45em;
  }

  .to-top {
    background-color: ${colors.primary};
    text-decoration: none;
    color: white;
    border-radius: 25px;
    padding: 14px 25px;
    display: inline-block;
    margin-top: 1em;
    -webkit-letter-spacing: 3px;
    -moz-letter-spacing: 3px;
    -ms-letter-spacing: 3px;
    letter-spacing: 3px;
    font-size: 14px;
    font-family: ${fonts.subHead};
    cursor: pointer;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    justify-self: center;
    :hover {
      transition: 0.3s all ease;
      background-color: ${colors.secondaryRed};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .content-container {
      display: flex;
      justify-content: flex-end;
      .right-container {
        width: 70%;
        padding-left: 40px;
      }
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .left-container {
      width: 20%;
    }
  }
`;

class WhitePaperFocus extends React.Component {
  render() {
    const post = this.props.item;
    const allServices = this.props.allServices;
    const allTopics = this.props.allTopics;
    let serviceLinks = "";
    for (var key in allServices.edges) {
      for (var topicKey in post.our_solutions) {
        if (
          post.our_solutions[topicKey] === allServices.edges[key].node.wordpress_id
        ) {
          serviceLinks +=
            "<a href='/solutions/" +
            allServices.edges[key].node.slug +
            "'>" +
            allServices.edges[key].node.name +
            "</a><br />";
        }
      }
    }

    let topicLinks = "";
    for (var key in allTopics.edges) {
      for (var topicKey in post.topic) {
        if (post.topic[topicKey] === allTopics.edges[key].node.wordpress_id) {
          topicLinks +=
            "<a href='/insights/white-papers/?=" +
            allTopics.edges[key].node.wordpress_id +
            "'>" +
            allTopics.edges[key].node.name +
            "</a><br />";
        }
      }
    }

    return (
      <StyledWhitePaper className="animate">
        {!this.props.isPreviewMode && (
          <SEO
            isBlogPost={true}
            postData={post}
            postImage={post.better_featured_image.source_url}
          />
        )}

        <NavBar name="White Papers" link="/insights/white-papers" />
        <h1 className="page-container" id="jump-to">
          {post.title}
        </h1>
        <div className="page-container content-container">
          <div className="left-container">
            <h3>Topics</h3>
            {ReactHtmlParser(topicLinks)}
            <p></p>
            <div className="bottom-line"></div>
            <h3>Solutions</h3>
            {ReactHtmlParser(serviceLinks)}
            <p></p>
            <div className="bottom-line"></div>
            <h3>Share</h3>
            <ShareWidget className={"social"} />
          </div>
          <div className="right-container">
            <CircleSection
              whitePaperPost={true}
              background={post.better_featured_image.source_url}
              slug={post.slug}
              download={
                this.props.isPreviewMode ? "" : post.acf.file_to_download.source_url
              }
            />
            <p>{ReactHtmlParser(post.content)}</p>

            {/*Here we hardcode the post we want to gate the hubspot form*/}
            {/*post.slug === 'demystifying-decisioning-orchestration-the-power-behind-customer-journeys' ? <></> : <a href={post.acf.file_to_download.source_url} className="to-top">DOWNLOAD</a>*/}

          </div>
        </div>
        <ContactBanner />
      </StyledWhitePaper>
    );
  }
}

export default WhitePaperFocus;
