import React, { Fragment, Component } from 'react';
import { Link, graphql } from 'gatsby';
import format from 'date-fns/format';
import { colors } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import WhitePaperFocus from '../../components/WhitePapersGrid/WhitePaperFocus';

class WhitePaperTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  item = this.props.data.wordpressWpWhitePaper;
  allSolutions = this.props.data.allWordpressWpOurSolutions;
  allTopics = this.props.data.allWordpressWpTopic;

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/white_paper/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const previewData = this.state.previewPayload;

    if (this.state.isPreviewMode) {
      this.item.title = previewData.title.rendered;
      this.item.content = previewData.content.rendered;
    }

    return <WhitePaperFocus item={this.item} allServices={this.allSolutions} allTopics={this.allTopics} />;
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpWhitePaper(slug: { eq: $slug }) {
      title
      content
      date(formatString: "MMMM D, YYYY")
      topic
      vertical
      slug
      type
      our_solutions
      acf {
        seo_title
        seo_canonical
        seo_description
        featured_post
        file_to_download {
          source_url
        }
      }
      better_featured_image {
        source_url
        description
        caption
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
  }
`;

export default WhitePaperTemplate;
