import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../style-utilities/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";

const StyledShareWidget = styled.div`
display: flex;
margin-bottom: 2em;
  .SocialMediaShareButton {
    margin-right: 20px;
    outline: none;
  }
`;

const ShareWidget = ({ className }) => {
  const isBrowser = typeof window !== "undefined";
  // let convertToHttp = String(window.location).split('');
  // convertToHttp.splice( 4, 1);
  // convertToHttp = convertToHttp.toString;
  
  return (
    <StyledShareWidget className={className}>
      <TwitterShareButton url={isBrowser ? String(window.location) : ""}>
        <FontAwesomeIcon
          icon={faTwitterSquare}
          size="2x"
          color={`${colors.primaryGray}`}
        />
      </TwitterShareButton>
      <FacebookShareButton url={isBrowser ? String(window.location) : ""}>
        <FontAwesomeIcon
          icon={faFacebookSquare}
          size="2x"
          color={`${colors.primaryGray}`}
        />
      </FacebookShareButton>
      <LinkedinShareButton url={isBrowser ? String(window.location) : ""}>
        <FontAwesomeIcon
          icon={faLinkedin}
          size="2x"
          color={`${colors.primaryGray}`}
        />
      </LinkedinShareButton>
    </StyledShareWidget>
  );
};

export default ShareWidget;
