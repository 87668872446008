import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { breakpoints, fonts, colors } from "../../../style-utilities/variables";
import ShareWidget from "../../../components/ShareWidget";


const StyledDetailsSidebar = styled.div`
  margin-top: 4em;
  margin-right: 2em;
  margin-left: auto;
  .client-logo {
    width: 100%;
    margin-bottom: 2em;
    img {
      width: 150px;
    }
  }
  .left-subDivs {
    width: 50%;
    padding-right: 20px;
    max-width: 280px;
    float: left;
  }
  .right-subDivs {
    max-width: 280px;
    width: 50%;
    float: right;
  }

  .subDivs {
    padding-bottom: 0.7rem;
    h3 {
      margin-bottom: 0.6rem;
    }
    ul {
      margin-bottom: 0;
      margin-left: 0;
    }
    p,
    li {
      font-size: 16px;
      list-style: none;
      color: ${colors.primaryGray};
      margin-bottom: 0;
    }
    a {
      color: ${colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 210px;
    .client-logo {
      margin-bottom: 0;
    }
    .right-subDivs,
    .left-subDivs {
      width: 100%;
    }
    .left-subDivs {
      padding-right: 0;
    }
  }
`;
const CaseStudySideBar = props => {
  return (
    <StyledDetailsSidebar>
      <div className="client-logo">
        <img src={props.logo} />
      </div>
      <div className="left-subDivs">
        <div className="client subDivs bottom-line">
          <h3>Client</h3>
          <p>{props.client}</p>
        </div>
        <div className="vertical subDivs bottom-line">
          <h3>Industry</h3>
          <ul>
            {props.verticals.map((item, idx) => {
              return <li key={"vertical-" + idx}>{item.name}</li>;
            })}
          </ul>
        </div>
        <div className="topic subDivs bottom-line">
          <h3>Topics</h3>
          <ul>
            {props.topics.map((item, idx) => {
              return (
                <li key={"topics-" + idx}>
                  <Link to={"/results/case-studies/?=" + item.wordpress_id}>
                    {ReactHtmlParser(item.name)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="right-subDivs">
        <div className="services subDivs bottom-line">
          <h3>Solutions</h3>
          <ul>
            {props.services.map((item, idx) => {
              return (
                <li key={"solutions-" + idx}>
                  <Link to={"/solutions/" + item.slug}>
                    {ReactHtmlParser(item.name)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {props.technology.length ? (
          <div className="technology subDivs bottom-line">
            <h3>Technology</h3>
            <ul>
              {props.technology.map((item, idx) => {
                return (
                  <li key={"technology-" + idx}>
                    <Link to={"/technology/" + item.slug}>{item.name}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}
        <div className="share subDivs">
          <h3>Share</h3>
          <ShareWidget />
        </div>
      </div>
    </StyledDetailsSidebar>
  );
};

export default CaseStudySideBar;
