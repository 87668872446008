import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";

const StyledBlock = styled.div`
  /* **ST */
  display: none;
  
  margin: 1.25em 2em;
  @media (min-width: ${breakpoints.dekstop}) {
    margin: 1em;
  }
  h4 {
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  li {
    list-style: none;
    display: inline;
  }
  .services {
    color: ${colors.black};
    li {
      margin-right: 1em;
    }
  }
  .services {
    li {
      text-decoration: underline;
      font-style: italic;
      text-transform: capitalize;
    }
    li:after {
      content: ",";
    }
    li:last-child:after {
      content: "";
    }
  }
  ul {
    margin-left: 0;
  }
  .services {
    a {
      text-transform: inherit;
      font-size: 0.75rem;
      font-weight: 600;
      color: #78b9cd;
      clear: both;
    }
  }
`;

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const TagsContainer = ({ tags, className }) => {
  const tagTopics = tags;

  var renderTags = "";

  for (var topicKey in tags) {
    renderTags += tags[topicKey] + "<br>";
  }

  return (
    <StyledBlock className={className}>
      <br />
      <h4>Services</h4>
      <span className="services">
        {ReactHtmlParser(renderTags)}
        <br />
      </span>
    </StyledBlock>
  );
};

export default TagsContainer;
