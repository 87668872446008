import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import CaseStudySideBar from "../components/CaseStudies/SingleCaseStudy/CaseStudySideBar";
import BlogSideBar from "../components/Blog/BlogSideBar";
import FormikForm from "../components/WhitePapersGrid/FormikForm";


const StyledHero = styled.div`
  position: relative;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  .bottom-line {
    border-bottom: 2px solid ${colors.lightGray};
    margin-bottom: 1.45em;
  }
  .challenge-copy {
    padding-right: 1em;
    img {
      height: auto;
    }
  }

  .width-full {
    width:100% !important;
  }

  .left-form {
    padding-right: 1em;
  }
  .right-container {
    position: relative;
    display: flex;
    flex-direction: column;
    .circle-container {
      width: 450px;
      margin: 2em 0 3em;
      align-self: center;
      padding-top: 450px;
      border-radius: 50%;
      right: calc(50% - 217px);
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      object-fit: cover;
      @media (min-width: 400px) {
        left: auto;
        margin: 2em auto;
      }
    }
    .subHead {
      font-size: 22px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    justify-content: space-around;
    flex-direction: row;
    padding: 0 0 0 2.5em;
    .left-form {
      width: 28%;
      padding-right: 0;
    }
    .left-container {
      width: 22%;
      min-width: 230px;
    }
    .right-container {
      width: 70%;
      max-width: 860px;
      padding-top: 0;
      padding-right: 1rem;
      .circle-container {
        width: 75%;
        padding-top: 75%;
        left: 0;
        right: 0;
        position: absolute;
      }
      .challenge-copy {
        padding: 88% 0 4em .5em;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 0 0 4.5em;
    .left-container {
      width: 30%;
      margin-right: -30px;
    }
    .right-container {
      width: 70%;
    }
    .challenge-copy {
      max-width: 80%;
    }
  }
`;
const Hero = ({
  caseStudy,
  blogPost,
  whitePaperPost,
  logo,
  download,
  client,
  date,
  photo,
  authorName,
  authorLink,
  title,
  bio,
  background,
  underCircle,
  postCopy,
  topics,
  tagId,
  verticals,
  services,
  technology,
  slug
}) => {
  const heroContainBG = {
    backgroundImage: "url(" + background + ")"
  };
  return (
    <StyledHero>
        {caseStudy ? (
          <div className="left-container">
          <CaseStudySideBar
            logo={logo}
            client={client}
            topics={topics}
            verticals={verticals}
            services={services}
            technology={technology}
          />
          </div>
        ) : (
          <></>
        )}
        {blogPost ? (
          <div className="left-container">
          <BlogSideBar
            date={date}
            photo={photo}
            authorName={authorName}
            authorLink={authorLink}
            title={title}
            bio={bio}
            topics={topics}
            tagId={tagId}
            services={services}
          />
          </div>
        ) : (
          <></>
        )}

        {/*Here we hardcode the post we want to gate the hubspot form*/}
        {/*slug === 'demystifying-decisioning-orchestration-the-power-behind-customer-journeys' ? <div className="left-form"><FormikForm download={download} /></div> : <></>*/}
        { whitePaperPost ? <div className="left-form"><FormikForm download={download} /></div> : <></> }

        <div className={whitePaperPost ? "right-container width-full" : "right-container"}>
        <div className="circle-container" style={heroContainBG}></div>
        <div
          className={
            caseStudy ? "challenge-copy small" : "challenge-copy medium"
          }
        >
          {caseStudy ? <h3>Challenge</h3> : <></>}
          {underCircle ? <div className="subHead">{ReactHtmlParser(underCircle)}</div> : <></>}
          {postCopy ? <p>{ReactHtmlParser(postCopy)}</p> : <></>}
        </div>
      </div>
    </StyledHero>
  );
};

export default Hero;
